<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentPageChange"
    :current-page="currentPage"
    :page-sizes="pageSizes"
    :page-size.sync="currentPageSize"
    :layout="layout || 'total, sizes, prev, pager, next, jumper'"
    :total="total"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    handleCurrentPageChange: Function,
    handleSizeChange: Function,
    total: Number,
    pageNum: {
      type: Number,
      required: false
    },
    pageSizes: {
      type: Array,
      default: () => [8, 16, 24, 32],
    },
    layout: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentPage: 1,
      currentPageSize: this.pageSizes ? this.pageSizes[0] : 8,
    };
  },
  watch: {
    pageNum: function (val) {
      if (val === 0 || val) {
        this.currentPage = val;
      }
    }
  },
  methods: {
    setPageSize(s) {
      this.currentPageSize = s;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  text-align: right;
  margin: 24px 0 24px 0;
}
.el-pagination__total {
  font-size: 14px !important;
}
</style>