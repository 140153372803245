<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import { dateTransform, toThousands } from "@/utils/utils.js";

export default {
  props: ['pageNum'],
  data() {
    return {
      tableColumns: [
        {
          label: "图片名称",
          prop: "file_real_name",
          width: 200
        },
        {
          label: "图片类型",
          prop: "file_type",
          width: 130
        },
        {
          label: "图片路径",
          prop: "file_down_path",
          width: 200
        },
        {
          label: "图片大小（kb）",
          prop: "file_size"
        },
        {
          label: "创建日期",
          prop: "create_time",
          width: 150
        },
        {
          label: "创建人",
          prop: "create_by"
        },
        {
          label: "操作",
          prop: "operations",
          width: "120px"
        },
      ],
      formatMap: {
        file_type: {}
      },
      toThousands,
      dialogImageUrl: '',
      dialogVisible: false,
    };
  },
  watch: {
    materialTypeList: function(val) {
      if (val && val.length > 0) {
        let map = {};
        val.map((item) => {
          map[item.value] = item.label;
        });
        this.formatMap.file_type = map;
      }
    }
  },
  computed: {
    ...mapGetters(["materialTableDatas", "materialTableListTotal", "materialTypeList"]),
    formatTime() {
      return (val) => {
        return dateTransform(val) || "";
      };
    },
    tableHeight() {
      let h = document.body.clientHeight - 60 - 20 - 20 - 47 - 32 - 15 - 21;
      h = h - 28 - 15; 
      return h;
    }
  },
  methods: {
    ...mapActions(['deleteMaterialData']),
    handleSelectionChange(val) {
      this.$emit("selectChange", val);
    },
    getTableData(params) {
      this.$emit("getDatas", params);
    },
    getFormatName(key, val) {
      return this.formatMap[key][val] || '';
    },
    handleView(row) {
      this.dialogImageUrl = row.file_down_path;
      this.dialogVisible = true;
    },
    async deleteData(row) {
      try {
        this.$changeLoadingState(true);
        await this.deleteMaterialData({ids: [row.id]});
        this.$changeLoadingState(false);
        this.getTableData();
      } catch (error) {
        console.log(error);
        this.$changeLoadingState(false);
      }
    }
  },
  components: {
    Pagination,
  },
};
</script>
<template>
  <div>
    <el-table
      :data="materialTableDatas"
      style="width: 100%"
      class="projectsTable"
      border
      :height="tableHeight"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ formatTime(scope.row[c.prop]) }}
          </div>
          <div v-else-if="!!formatMap[c.prop]">
            {{ getFormatName(c.prop, scope.row[c.prop]) }}
          </div>
          <div v-else-if="c.prop === 'file_size'">
            {{ toThousands(scope.row[c.prop]) }}
          </div>
          <div v-else-if="c.prop === 'file_down_path'" class="table-ellipsis-text" :title="scope.row[c.prop]">
            <a target="_blank" :href="scope.row[c.prop]">{{scope.row[c.prop]}}</a>
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-button
              size="mini"
              type="text"
              @click="handleView(scope.row)"
            >
              查看
            </el-button>
            <el-popconfirm
              title="确定要删除当前数据吗？"
              @confirm="deleteData(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                class="delete-text-btn"
                style="margin-left: 10px;"
              >
                删除
              </el-button>
            </el-popconfirm>
          </div>
          <span v-else class="table-ellipsis-text" :title="scope.row[c.prop]">{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="materialTableListTotal > 0"
      :handleSizeChange="
        (v) => {
          this.getTableData({ page_size: v });
        }
      "
      :handleCurrentPageChange="
        (v) => {
          this.getTableData({ page_num: v });
        }
      "
      :total="materialTableListTotal"
      :pageNum="pageNum"
    />
    <el-dialog title="图片查看" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>