<script>
import { mapActions } from "vuex";
import filterComp from "./filter";
import tableComp from "./table";
import editor from './editor';

export default {
  data() {
    return {
      toolbarOperations: [
        {
          text: "添加素材",
          key: "add",
        },
        {
          text: "批量删除",
          key: "delete",
        },
      ],
      pageInfo: {
        page_num: 1,
        page_size: 8
      },
      oldParams: {},
      checkDatas: []
    };
  },
  components: {
    filterComp,
    tableComp,
    editor
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions([
      "getMaterialTableList",
      "deleteMaterialData"
    ]),
    async getList(params, isFirstPage) {
      if (isFirstPage) {
        this.pageInfo.page_num = 1;
      }
      const filterVal = this.$refs.filter.getValue();
      let queryParams = {
        ...this.oldParams,
        ...filterVal,
        ...this.pageInfo
      }
      if (params) {
        Object.keys(this.pageInfo).forEach((key) => {
          if (params.hasOwnProperty(key)) {
            this.pageInfo[key] = params[key];
          }
        })
        queryParams = {
          ...queryParams,
          ...params
        };
      }
      try {
        this.$changeLoadingState(true);
        await this.getMaterialTableList(queryParams);
        this.checkDatas = [];
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
      this.oldParams = queryParams;
    },
    operationHandler(key) {
      switch (key) {
        case 'add':
          this.$refs.singleEditor.show();
          break;
        case 'delete':
          this.batchDelete();
          break;
        default:
          break;
      }
    },
    async batchDelete() {
      if (this.checkDatas.length === 0) {
        this.$message.error('请先勾选要删除的数据');
        return;
      }
      const ids = this.checkDatas.reduce((arr, item) => {
        arr.push(item.id);
        return arr;
      }, []);
      try {
        this.$changeLoadingState(true);
        await this.deleteMaterialData({ids: ids});
        this.$changeLoadingState(false);
        this.getList();
      } catch (error) {
        console.log(error);
        this.$changeLoadingState(false);
      }
    },
    search() {
      this.getList(null, true);
    },
    selectChange(val) {
      this.checkDatas = val;
    },
    saveSuccess() {
      this.getList(null, true);
    }
  },
};
</script>
<template>
  <div class="main-container">
    <filter-comp ref="filter" @search="search"></filter-comp>
    <div class="toolbarOperations">
      <el-button
        size="mini"
        v-for="t in toolbarOperations"
        :key="t.key"
        :type="t.key == 'delete' ? 'danger' : 'primary'"
        @click="operationHandler(t.key)"
        >{{ t.text }}</el-button
      >
    </div>
    <table-comp
      :pageNum="pageInfo.page_num"
      @selectChange="selectChange"
      @getDatas="getList"
    ></table-comp>
    <editor ref="singleEditor" @success="saveSuccess"></editor>
  </div>
</template>
<style lang="scss" scoped>
.main-container {
  height: 100%;
  background: #fff;
  padding: 10px;
}
</style>
<style lang="scss">
.toolbarOperations {
  margin-bottom: 16px;
//   .el-button {
//     padding: 10px 12px;
//   }
}
</style>
